// ============================================================================
// Gallery
// ============================================================================

.gallery {
  div[class^='col-'] {
    position: relative;
  }
  .img-wrap {
    transition: all ease 500ms;
    left: 15px;
    right: 15px;
    @media (min-width: $screen-sm-min) {
      bottom: 20px;
    }
  }
  .img-responsive {
    margin-bottom: 20px;
  }
  a {
    display: block;
  }
}