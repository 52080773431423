// ============================================================================
// Header
// ============================================================================

.header {
  background-size: cover;
  position: relative;
  background-attachment: scroll;
  background-repeat: no-repeat;
  &.full {
    background-position: center;
    background-image: url("/themes/default/images/home_bg.jpg");
    min-height: 340px;
    @media (min-width: $screen-xs-min) {
      min-height: 420px;
    }
    @media (min-width: $screen-sm-min) {
      min-height: 520px;
    }
    @media (min-width: $screen-md-min) {
      background-size: auto;
      background-position: 0 0;
      min-height: 700px;
    }
    @media (min-width: $screen-lg-min) {
      background-attachment: fixed;
    }
    .caption {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 20px;
      @media (min-width: $screen-sm-min) {
        bottom: 40px;
      }
      @media (min-width: $screen-md-min) {
        bottom: 80px;
      }
      .content {
        > * {
          float: right;
          clear: right;
          display: inline-block;
          margin-bottom: 0;
          padding: 5px 15px;
          color: #FFF;
          background-color: $theme-primary-trans;
          font-size: 14px;
          line-height: 20px;
          @media (min-width: $screen-xs-min) {
            padding: 10px 30px;
            font-size: 22px;
            line-height: 32px;
          }
          @media (min-width: $screen-md-min) {
            font-size: 40px;
            line-height: 54px;
          }
        }
      }
    }
  }
  &.half {
    background-image: url("/themes/default/images/inner_bg.jpg");
    @media (min-width: $screen-sm-min) {
      min-height: 300px;
    }
    @media (min-width: $screen-md-min) {
      min-height: 350px;
    }
    @media (min-width: $screen-lg-min) {
      background-size: contain;
      background-attachment: fixed;
      min-height: 420px;
    }
    .inner {
      @media (min-width: $screen-md-max) {
        background-color: rgba(255, 255, 255, 0.75);
      }
    }
  }
  .inner {
    @media (min-width: $screen-md-max) {
      background-color: rgba(255, 255, 255, 0.29);
      padding: 10px 0;
    }
    .title {
      display: inline-block;
      margin-top: 20px;
      font-family: $font-family-sans-serif-alt;
      color: $theme-primary;
      font-size: 30px;
      line-height: 36px;
    }
    .search-form {
      max-width: 250px;
      margin-bottom: 0;
      .input-group {
        .form-control {
          background-color: rgba(255, 255, 255, 0.49);
        }
      }
    }
  }
}
