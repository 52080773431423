.well {
  border: 0;
  border-radius: 0;
  box-shadow: none;
  padding: 20px;
  background-color: rgba(255, 255, 255, 0.7);
  margin-bottom: 20px;
  @media (min-width: $screen-sm-min) {
    padding: 20px 80px;
  }
  &.thin {
    padding: 20px;
    border-radius: 6px;
  }
  &.light {
   background-color: #FFF;
  }
}