// ============================================================================
// Service Page
// ============================================================================

.split-banner {
  .caption {
    text-align: center;
    position: absolute;
    left: 15px;
    right: 15px;
    bottom: 0;
    padding: 10px;
    background-color: rgba(0, 0, 0, 0.7);
    h3 {
      font-size: 16px;
      color: #FFF;
      @media (min-width: $screen-sm-min) {
        font-size: 22px;
      }
    }
    @media (min-width: $screen-sm-min) {
      padding: 20px 40px 40px;
    }
  }
  .img-responsive {
    min-width: 100%;
  }
  div[class^='col-'] {
    margin-bottom: 20px;
    @media (min-width: $screen-sm-min) {
      margin-bottom: 0;
    }
    &:first-of-type {
      @media (min-width: $screen-sm-min) {
        padding-left: 0;
        padding-right: 5px;
        .caption {
          left: 0;
          right: 5px;
        }
      }
    }
    &:last-of-type {
      @media (min-width: $screen-sm-min) {
        padding-left: 5px;
        padding-right: 0;
        .caption {
          left: 5px;
          right: 0;
        }
      }
    }
  }
}