// ============================================================================
// Home Page Layout
// ============================================================================

.gateways {
  div[class^='col-'] {
    @media (min-width: $screen-sm-min) {
      padding-left: 5px;
      padding-right: 5px;
    }
  }
}

.testimonial {
  margin-top: 40px;
}

.img-profile {
  position: relative;
  @media (min-width: $screen-sm-min) {
    margin-bottom: 60px;
    padding-right: 15px;
    border-right: 6px solid #f3f3f3;
  }
  .img-wrap {
    right: 15px;
  }
}

.quote {
  padding: 16px 14px;
  border-radius: 9px;
  background-color: #f3f3f3;
  .author {
    display: inline-block;
    font-style: italic;
    margin-top: 10px;
  }
  div[class^='col-'] {
    .quote-mark {
      font-family: Lato, sans-serif;
      color: $theme-primary;
      font-size: 120px;
      line-height: 130px;
    }
    &:first-of-type, &:last-of-type {
      text-align: center;
      padding-left: 5px;
      padding-right: 5px;
    }
  }
}