// ============================================================================
// Buttons
// ============================================================================

.btn-theme-primary {
  @extend .btn;
  white-space: normal !important;
  word-wrap: break-word;
  transition: all ease 500ms;
  color: #FFF;
  background-color: $theme-primary;
  &:hover, &:focus, &:active {
    color: #FFF;
    outline: 0;
    background-color: #000;
  }
}

.btn-action, .btn-block {
  border-radius: 13px;
  display: block;
  width: 100%;
  background-color: $theme-primary-trans;
  &:hover, &:focus, &:active {
    background-color: $theme-primary;
  }
}

.btn-action {
  overflow: auto;
  padding: 0 20px;
  h3 {
    font-size: 18px;
    color: #FFF;
    float: left;
    line-height: 48px;
    display: inline-block;
    width: auto;
    @media (min-width: $screen-lg-min) {
      font-size: 22px;
    }
  }
  span {
    float: right;
    background-color: $theme-primary;
    padding: 20px;
    display: inline-block;
    width: auto;
    margin-right: -20px;
    @media (min-width: $screen-lg-min) {

    }
  }
}

.btn-block {
  padding: 20px 0;
  span {
    opacity: 0.7;
  }
  h3 {
    text-align: left;
    padding-left: 15px;
    border-left: 3px solid rgba(255, 255, 255, 0.4);
    color: #FFF;
    &:after {
      content: '\003E';
    }
  }
}

.btn-circle {
  padding: 8px 12px;
  margin-left: 5px !important;
  border-radius: 50% !important;
}

.btn-blog {
  display: block;
  margin-top: 15px;
  color: $theme-primary;
  &:after {
    content: '\00A0\003E';
  }
  &.reverse {
    &:before {
      content: '\003C\00A0';
    }
    &:after {
      content: '';
    }
  }
}