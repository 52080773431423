// ============================================================================
// Inner Page Layout
// ============================================================================

.texture {
  padding-top: 40px;
  padding-bottom: 50px;
  background-color: #f3f3f3;
  &.contoured {
    background-image: url('/themes/default/images/texture.png');
  }
  &.gradient {
    background-image: linear-gradient(rgba(0, 0, 0, 0.2), transparent 32%, transparent), url('/themes/default/images/texture.png');
  }
  &.thin {
    padding-top: 22px;
    padding-bottom: 22px;
    min-height: 50px;
  }
}