// ============================================================================
// Main Stylesheet
// ============================================================================

// Imports
// ----------------------------------------------------------------------------

@import url(https://fonts.googleapis.com/css?family=Lato:400,100,100italic,300,300italic,400italic,700,700italic,900,900italic|Open+Sans:400,300,300italic,400italic,600,600italic,700,700italic,800,800italic);
@import url(https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.4.0/css/font-awesome.min.css);
@import 'open-iconic/font/css/open-iconic-bootstrap.scss';

// Site variables and mixins
@import 'variables';
@import 'mixins';

// Custom Bootstrap
@import 'bootstrap';

// Core CSS
@import 'scaffolding';
@import 'forms';
@import 'buttons';
@import 'panels';
@import 'wells';
@import 'typography';

// Components
@import 'browser_update';
@import 'webflow_icons';
@import 'webflow_lightbox';
@import 'jumbotron';
@import 'navbar';
@import 'hamburger';
@import 'map_interaction';
@import 'responsive_helper';

// Includes
@import 'header';
@import 'footer';
@import 'search';

// SilverStripe - Bootstrap conversions
@import 'silverstripe_nav';
@import 'silverstripe_forms';
@import 'silverstripe_images';

// Overrides
@import 'overrides';

// Layouts
@import 'inner';
@import 'homepage';
@import 'blog';
@import 'service';
@import 'gallery';