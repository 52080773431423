// ============================================================================
// Panels
// ============================================================================

.panel-theme {
  @extend .panel;
  background-color: transparent;
  .panel-heading {
    padding: 0;
    border-radius: 0;
    background-color: $theme-primary;
    a {
      padding: 5px 15px;
      display: block;
      h3 {
        font-size: 18px;
        color: #FFF;
        @media (min-width: $screen-lg-min) {
          font-size: 22px;
        }
      }
      &:hover, &:active, &:focus {
        background-color: #000;
      }
    }
  }
  .panel-body {
    position: relative;
    padding: 0;

  }
  .panel-footer {
    border-radius: 0;
    background-color: $theme-secondary;
    > * {
      color: #FFF;
      font-size: 14px;
      line-height: 24px;
      font-weight: 300;
    }
  }
  &.small {
    .panel-footer {
      min-height: 100px;
      background-color: #FFF;
      > * {
        color: #333;
        font-weight: normal;
      }
    }
  }
}