// ============================================================================
// Search
// ============================================================================

.search-form {
  margin-bottom: 30px;
  .input-group {
    .form-control {
      border-radius: 20px;
      margin-top: 2px;
    }
  }
}

.highlight {
  font-weight: bold;
}