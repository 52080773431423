// ============================================================================
// Scaffolding
// ============================================================================

body {

}

a[data-scroll] {
  cursor: pointer;
}

.strong {
  font-weight: bold !important;
}

.fake-link {
  color: #0000FF;
  text-decoration: underline;
  &:hover {
    cursor: pointer;
  }
}

.img-centered {
  &.img-responsive {
  margin-left: auto;
  margin-right: auto;
  }
  .img-responsive {
    margin-left: auto;
    margin-right: auto;
  }
}

.img-restricted {
  &.img-responsive {
    width: 100%;
    max-width: 405px;
    @media (min-width: $screen-sm-min) {
      width: auto;
      max-width: 100%;
    }
  }
  .img-responsive {
    width: 100%;
    max-width: 405px;
    @media (min-width: $screen-sm-min) {
      width: auto;
      max-width: 100%;
    }
  }
}

.img-wrap {
  transition: all ease 500ms;
  z-index: 0;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  &:hover, &:active, &:focus {
    background-color: rgba(0, 0, 0, 0.4);
  }
}

.map {
  min-height: 200px;
  @media (min-width: $screen-sm-min) {
    min-height: 400px;
  }
}