// ============================================================================
// Navbar
// ============================================================================

.navbar {
  // Collapsed Styles
  // background-color: ; // Navbar background colour
  // border-color: ; // Navbar border colour
  z-index: 1;
  margin-bottom: 0;
  @media (min-width: $grid-float-breakpoint) {
    margin-top: 10px; // Space from above content
  }
  .navbar-header {
    button {
      transition: all ease 500ms;
      border: 0;
      border-radius: 50%;
      padding: 25px 28px;
      background-color: $theme-secondary;
      i {
        color: #FFF;
      }
      &:hover, &:active, &:focus {
        background-color: $theme-primary;
      }
    }
  }
  .navbar-brand {
    margin-left: -20px;
    margin-top: -90px;
    @media (min-width: 625px) {
      margin-left: 0;
      margin-top: -95px;
    }
    img {
      display: inline-block;
      max-width: 190px;
      @media (min-width: 625px) {
        max-width: 220px;
      }
    }
    span {
      display: none;
      font-size: 14px;
      line-height: 20px;
      color: $theme-primary;
      font-family: $font-family-sans-serif-alt;
      @media (min-width: 625px) {
        display: inline-block;
      }
      @media (min-width: $screen-sm-min) {
        font-size: 18px;
        line-height: 26px;
      }
    }
  }
  .navbar-search {
    .search-form {
      margin: 10px auto 0;
    }
  }
  .navbar-nav {
    font-size: 0; //  Inline block white space fix
    li {
      a {
        font-size: 16px;
        font-weight: 600;
        border-bottom: 4px solid transparent;
        padding: 15px 15px 11px; // Border Fix
        background-color: #FFF;
        color: #000; // Navbar link colour

        &:hover, &:focus, &:active {
          color: $theme-primary; // Navbar link colour when hovered/focused/activated
          background-color: #FFF; // Navbar link background colour when hovered/focused/activated
          border-bottom-color: $theme-primary;
        }
      }
      &.current {
        a {
          color: $theme-primary; // Navbar link colour when current (SS)
          background-color: #FFF; // Navbar link background colour when current (SS)
          border-bottom-color: $theme-primary;
          &:hover, &:focus, &:active {
            color: $theme-primary; // Navbar link colour when hovered/focused/activated and current (SS)
            background-color: #FFF; // Navbar link background colour when hovered/focused/activated and current (SS)
          }
        }
      }
    }
    .open {
      a {
        color: #000; // Navbar link colour when open
        background-color: transparent; // Navbar link background colour when open
        &:hover, &:focus, &:active {
          color: $theme-primary; // Navbar link colour when open and hovered/focused/activated
          background-color: #FFF; // Navbar link background colour when open and hovered/focused/activated
        }
      }
      .dropdown-menu {
        border: 0;
        border-radius: 0;
        padding: 0;
        box-shadow: none;
        // background-color: ; // Navbar dropdown background colour
        // border-color: ; // Navbar dropdown border colour
        li {
          a {
            color: #000;
            // background-color: ; // Navbar dropdown link background colour
            // border-color: ; // Navbar dropdown link Navbar border colour
            &:hover, &:focus, &:active {
              color: $theme-primary; // Navbar dropdown link colour when hovered/focused/activated
              // background-color: ; // Navbar dropdown link background colour when hovered/focused/activated
            }
          }
          &.current {
            a {
              // color: ; // Navbar dropdown link colour when current (SS)
              // background-color: ; // Navbar dropdown link background colour when current (SS)
              &:hover, &:focus, &:active {
                // color: ; // Navbar dropdown link colour when hovered/focused/activated and current (SS)
                // background-color: ; // Navbar dropdown link background colour when hovered/focused/activated and current (SS)
              }
            }
          }
        }
      }
    }
  }

  @media (min-width: $grid-float-breakpoint) {
    // Expanded Styles
    background-color: transparent; // Navbar background colour
    border-color: transparent; // Navbar border colour
    .navbar-nav {
      width: 100%;
      text-align: center;
      li {
        float: none;
        display: inline-block;
        a {
          background-color: rgba(255, 255, 255, 0.49); // Navbar link background colour
          // color: ; // Navbar link colour
          // background-color: ; // Navbar link background colour
          &:hover, &:focus, &:active {
            // color: ; // Navbar link colour when hovered/focused/activated
            // background-color: ; // Navbar link background colour when hovered/focused/activated
          }
        }
        &.current {
          a {
            // color: ; // Navbar link colour when current (SS)
            // background-color: ; // Navbar link background colour when current (SS)
            &:hover, &:focus, &:active {
              // color: ; // Navbar link colour when hovered/focused/activated and current (SS)
              // background-color: ; // Navbar link background colour when hovered/focused/activated and current (SS)
            }
          }
        }
      }
      .open {
        a {
          // color: ; // Navbar link colour when open
          // background-color: ; // Navbar link background colour when open
          &:hover, &:focus, &:active {
            // color: ; // Navbar link colour when open and hovered/focused/activated
            // background-color: ; // Navbar link background colour when open and hovered/focused/activated
          }
        }
        .dropdown-menu {
          background-color: transparent;
          margin-top: 4px;
          // border-color: ; // Navbar dropdown border colour
          li {
            width: 100%;
            a {
              background-color: rgba(255, 255, 255, 0.8);
              margin-bottom: 2px;
              border-bottom: 0;
              padding: 10px 15px;
              // background-color: ; // Navbar dropdown link background colour
              // border-color: ; // Navbar dropdown link Navbar border colour
              &:hover, &:focus, &:active {
                // color: ; // Navbar dropdown link colour when hovered/focused/activated
                background-color: #FFF; // Navbar dropdown link background colour when hovered/focused/activated
              }
            }
            &.current {
              a {
                // color: ; // Navbar dropdown link colour when current (SS)
                // background-color: ; // Navbar dropdown link background colour when current (SS)
                &:hover, &:focus, &:active {
                  // color: ; // Navbar dropdown link colour when hovered/focused/activated and current (SS)
                  // background-color: ; // Navbar dropdown link background colour when hovered/focused/activated and current (SS)
                }
              }
            }
          }
        }
      }
    }
  }
}