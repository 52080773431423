// ============================================================================
// Typography Stylesheet
//
// Defines additional styles which can be applied in the CMS
// ============================================================================

// ATTENTION: Button styles are now in 'editor.scss' due to the unfortunate repercussions of using @extend

// Bootstrap Styles
// Styles which you want users to be able to select in the CMS
// ----------------------------------------------------------------------------

.lead {
  // For the style to register, it needs some kind of definition.
  // Just apply the default display value
  display: block;
}

* {
  word-wrap: break-word;
}

h1 {
  margin-top: 5px;
  margin-bottom: 5px;
  color: $theme-primary;
  font-weight: 400;
  font-size: 30px;
  line-height: 45px;
  text-align: center;
  @media (min-width: $screen-sm-min) {
    text-align: left;
    font-size: 40px;
    line-height: 54px;
  }
  @media (min-width: $screen-md-min) {
    // font-size: ;
    // line-height: ;
  }
  @media (min-width: $screen-lg-min) {
    // font-size: ;
    // line-height: ;
  }
}

h2 {
  margin-top: 5px;
  margin-bottom: 5px;
  color: $theme-primary;
  font-size: 32px;
  line-height: 36px;
  font-weight: 400;
  @media (min-width: $screen-sm-min) {
    // font-size: ;
    // line-height: ;
  }
  @media (min-width: $screen-md-min) {
    // font-size: ;
    // line-height: ;
  }
  @media (min-width: $screen-lg-min) {
    // font-size: ;
    // line-height: ;
  }
}

h3 {
  margin-top: 10px;
  margin-bottom: 10px;
  color: $theme-primary;
  font-size: 22px;
  line-height: 30px;
  font-weight: 400;
  @media (min-width: $screen-sm-min) {
    // font-size: ;
    // line-height: ;
  }
  @media (min-width: $screen-md-min) {
    // font-size: ;
    // line-height: ;
  }
  @media (min-width: $screen-lg-min) {
    // font-size: ;
    // line-height: ;
  }
}

h4 {
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 18px;
  line-height: 24px;
  font-weight: 600;
  @media (min-width: $screen-sm-min) {
    // font-size: ;
    // line-height: ;
  }
  @media (min-width: $screen-md-min) {
    // font-size: ;
    // line-height: ;
  }
  @media (min-width: $screen-lg-min) {
    // font-size: ;
    // line-height: ;
  }
  a {
    color: $theme-primary;
  }
}

h5 {
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
  @media (min-width: $screen-sm-min) {
    // font-size: ;
    // line-height: ;
  }
  @media (min-width: $screen-md-min) {
    // font-size: ;
    // line-height: ;
  }
  @media (min-width: $screen-lg-min) {
    // font-size: ;
    // line-height: ;
  }
}

h6 {
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 12px;
  line-height: 18px;
  font-weight: 600;
  @media (min-width: $screen-sm-min) {
    // font-size: ;
    // line-height: ;
  }
  @media (min-width: $screen-md-min) {
    // font-size: ;
    // line-height: ;
  }
  @media (min-width: $screen-lg-min) {
    // font-size: ;
    // line-height: ;
  }
}

ol {
  li {
    margin-bottom: 15px;
    font-size: 15px;
    line-height: 26px;
    font-weight: 400;
  }
}

ul:not(.nav) {
  li:not(.link, .current) {
    margin-bottom: 15px;
    font-size: 15px;
    line-height: 26px;
    font-weight: 400;
  }
}

p {
  margin-bottom: 5px;
  font-size: 15px;
  line-height: 26px;
  font-weight: 400;
}

a {
  transition: all ease 500ms;
  &:hover, &:focus, &:active {
    text-decoration: none;
  }
}

hr {
  border-top-color: rgba(0, 0, 0, 0.2);
  margin-top: 22px;
  margin-bottom: 22px;
}

blockquote {

}

// Custom Styles
//
// Styles which will appear in the Styles dropdown in the CMS
// ----------------------------------------------------------------------------

.no-break {
  white-space: nowrap;
}
