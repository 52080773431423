// ============================================================================
// Bootstrap Imports
//
// Custom import of Bootstrap assets, to use _typography.scss, which includes
// the required Bootstrap type assets.
// You can also choose which Bootstrap components to include
// ============================================================================

// Core variables and mixins
@import 'bootstrap-sass-official/assets/stylesheets/bootstrap/variables';
@import 'bootstrap-sass-official/assets/stylesheets/bootstrap/mixins';

// Reset and dependcies
@import 'bootstrap-sass-official/assets/stylesheets/bootstrap/normalize';
@import 'bootstrap-sass-official/assets/stylesheets/bootstrap/print';
@import 'bootstrap-sass-official/assets/stylesheets/bootstrap/glyphicons';

// Core CSS
@import 'bootstrap-sass-official/assets/stylesheets/bootstrap/scaffolding';
@import 'bootstrap-sass-official/assets/stylesheets/bootstrap/type';
@import 'bootstrap-sass-official/assets/stylesheets/bootstrap/code';
@import 'bootstrap-sass-official/assets/stylesheets/bootstrap/grid';
@import 'bootstrap-sass-official/assets/stylesheets/bootstrap/tables';
@import 'bootstrap-sass-official/assets/stylesheets/bootstrap/forms';
@import 'bootstrap-sass-official/assets/stylesheets/bootstrap/buttons';

// Components
@import 'bootstrap-sass-official/assets/stylesheets/bootstrap/component-animations';
@import 'bootstrap-sass-official/assets/stylesheets/bootstrap/dropdowns';
@import 'bootstrap-sass-official/assets/stylesheets/bootstrap/button-groups';
@import 'bootstrap-sass-official/assets/stylesheets/bootstrap/input-groups';
@import 'bootstrap-sass-official/assets/stylesheets/bootstrap/navs';
@import 'bootstrap-sass-official/assets/stylesheets/bootstrap/navbar';
@import 'bootstrap-sass-official/assets/stylesheets/bootstrap/breadcrumbs';
@import 'bootstrap-sass-official/assets/stylesheets/bootstrap/pagination';
@import 'bootstrap-sass-official/assets/stylesheets/bootstrap/pager';
@import 'bootstrap-sass-official/assets/stylesheets/bootstrap/labels';
@import 'bootstrap-sass-official/assets/stylesheets/bootstrap/badges';
@import 'bootstrap-sass-official/assets/stylesheets/bootstrap/jumbotron';
@import 'bootstrap-sass-official/assets/stylesheets/bootstrap/thumbnails';
@import 'bootstrap-sass-official/assets/stylesheets/bootstrap/alerts';
@import 'bootstrap-sass-official/assets/stylesheets/bootstrap/progress-bars';
@import 'bootstrap-sass-official/assets/stylesheets/bootstrap/media';
@import 'bootstrap-sass-official/assets/stylesheets/bootstrap/list-group';
@import 'bootstrap-sass-official/assets/stylesheets/bootstrap/panels';
@import 'bootstrap-sass-official/assets/stylesheets/bootstrap/responsive-embed';
@import 'bootstrap-sass-official/assets/stylesheets/bootstrap/wells';
@import 'bootstrap-sass-official/assets/stylesheets/bootstrap/close';

// Components w/ JavaScript
@import 'bootstrap-sass-official/assets/stylesheets/bootstrap/modals';
@import 'bootstrap-sass-official/assets/stylesheets/bootstrap/tooltip';
@import 'bootstrap-sass-official/assets/stylesheets/bootstrap/popovers';
@import 'bootstrap-sass-official/assets/stylesheets/bootstrap/carousel';

// Utility classes
@import 'bootstrap-sass-official/assets/stylesheets/bootstrap/utilities';
@import 'bootstrap-sass-official/assets/stylesheets/bootstrap/responsive-utilities';
