// ============================================================================
// Inner Page Layout
// ============================================================================

.article-summary {
  h4 {
    margin: 0;
  }
  hr {
    margin-top: 10px;
  }
  .img-wrap {
    left: 15px;
    right: 15px;
  }
  .img-responsive {
    @extend .img-centered;
    @extend .img-restricted;
    margin-bottom: 20px;
  }
}

.img-post {
  display: inline-block;
  float: right;
  img {
    @extend .img-responsive;
    padding: 10px 15px;
  }
  @media (min-width: $screen-sm-min) {
    max-width: 50%;
  }
}

.pagination {
  li {
    * {
      background-color: transparent !important;
      border: 0 !important;
      color: #000 !important;
      padding: 6px 3px !important;
    }
    &:first-of-type {
      a {
        padding-right: 15px !important;
      }
    }
    &:last-of-type {
      a {
        padding-left: 15px !important;
      }
    }
  }
}