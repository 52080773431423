// ============================================================================
// Footer
// ============================================================================

.footer {
  background-color: #333;
  padding-bottom: 40px;
  * {
    color: #FFF;
    font-weight: 300;
  }
  a {
    &:hover, &:active, &:focus {
      color: rgba(255, 255, 255, 0.8);
    }
  }
  hr {
    border-top-width: 6px;
    border-top-color: rgba(243, 243, 243, 0.09);
  }
  .navbar-footer {
    text-align: center;
    margin: 40px auto 0;
    ul {
      li {
        display: inline-block;
        padding-right: 5px;
        margin-right: 10px;
        margin-bottom: 10px;
        border-right: 1px solid rgba(255, 255, 255, 0.4);
        &:last-of-type {
          margin-right: 0;
          padding-right: 0;
          border-right: 0;
        }
        @media (min-width: $screen-sm-min) {
          margin-bottom: 0;
          margin-right: 20px;
          padding-right: 20px;
        }
      }
    }
  }
  .content {
    text-align: center;
    margin-bottom: 30px;
    @media (min-width: $screen-sm-min) {
      text-align: left;
      padding-left: 15px;
      margin-bottom: 0;

    }
  }
  .full-row {
    @media (min-width: $screen-sm-min) {
      width: 100%;
      display: table;
      [class*="col-"] {
        float: none;
        display: table-cell;
        vertical-align: top;
        .ff-fix {
          position: absolute;
          top: 0;
          left: 15px;
          bottom: 0;
          border-left: 1px dotted rgba(255, 255, 255, 0.2);
        }
      }
    }
  }
}
